<script setup>
import * as _ from "lodash-es";
import {onMounted, ref} from 'vue'
import {useRoute} from "../composables/useRoute.js";

const subscribeLoading = ref(false);
const subscribeSuccess = ref(false);
const subscribeErrorMessage = ref(null);
const subscribeEmail = ref('');
const turnstileSuccessToken = ref('');
const route = useRoute();
const props = defineProps({
    cloudflareCaptchaSiteKey: {
        type: String,
    },
    email: {
        type: String,
        default: '',
    },
});
onMounted(() => {
    subscribeEmail.value = props.email;
    window.turnstile.ready(function () {
        window.turnstile.render('#cf-turnstile', {
            sitekey: props.cloudflareCaptchaSiteKey,
            callback: function(token) {
                turnstileSuccessToken.value = token;
            },
        });
    });

});
function subscribe()
{
    subscribeLoading.value = true;
    axios.post(route('subscribe.store'), {
        email   : subscribeEmail.value,
        'cf-turnstile-response'   : turnstileSuccessToken.value,
    })
        .then(function (response) {
            subscribeSuccess.value = true;
        })
        .catch(function (error) {
            if (_.get(error , 'response.data.message')) {
                subscribeErrorMessage.value = _.get(error , 'response.data.message');
            } else {
                subscribeErrorMessage.value = 'Unknown error.';
            }
            subscribeSuccess.value = false;
        })
        .finally(function () {
            subscribeLoading.value = false;
        });
}
</script>

<template>
    <div class="pt-12 pb-0 xl:pb-8">
        <div class="mx-auto max-w-7xl px-6 lg:px-8 mb-12">
            <h1 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Subscribe to our newsletter</h1>
            <p class="mt-2 text-sm leading-6 text-gray-600">
                Receive heartwarming success stories, highlights of shelters in need, and updates to our Kuranda ShelterBeds Program. Unsubscribe easily at any time.
            </p>
            <div v-if="subscribeSuccess" class="mt-12" role="alertdialog" aria-labelledby="subscribeResultSuccessLabel" aria-describedby="subscribeResultSuccessBody">
                <h3 class="text-sm font-semibold leading-6 text-gray-900" id="subscribeResultSuccessLabel">Success!</h3>
                <p class="mt-2 text-sm leading-6 text-gray-600" id="subscribeResultSuccessBody">You have been subscribed.</p>
            </div>
            <div v-if="subscribeErrorMessage && !subscribeSuccess" class="mt-12 text-sm leading-6 text-gray-600" role="alertdialog" aria-labelledby="subscribeResultErrorLabel" aria-describedby="subscribeErrorSuccessBody">
                <h3 class="text-sm font-semibold leading-6 text-gray-900" id="subscribeResultSuccessLabel">Error</h3>
                <p class="mt-2 text-sm leading-6 text-gray-600" id="subscribeResultErrorBody">
                    Sorry, there was an error with your request: {{ subscribeErrorMessage }} Please reload the page and try again.
                </p>
            </div>
            <form class="mt-12 sm:max-w-md" v-if="!subscribeSuccess && !subscribeErrorMessage" @submit.prevent="subscribe">
                <div>
                    <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                    <div class="mt-2">
                        <input autocomplete="email" required v-model="subscribeEmail" type="email" name="email" id="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="you@example.com" />
                    </div>
                </div>
                <div class="mt-8">
                    <span class="block text-sm font-medium leading-6 text-gray-900">
                        Complete the "Verify you are human test"
                    </span>
                    <div class="mt-2">
                        <div id="cf-turnstile" :data-sitekey="cloudflareCaptchaSiteKey"></div>
                    </div>
                </div>

                <button type="submit"
                        class="mt-8 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        :disabled="subscribeLoading"
                >
                    Subscribe
                </button>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
