<template>
        <div class="pt-12 pb-0 xl:pb-8">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-4xl sm:text-center">
                    <h1 class="mt-2 text-3xl text-gray-900 sm:text-4xl font-alternate">Dogs and Cats in shelters are <strong>stressed</strong>.</h1>
                    <p class="text-xl leading-7 text-gray-800 mt-10">
                        A <strong>comfortable bed</strong> can help reduce animals’ anxiety while they wait for their forever home.
                        <span class="mt-3 block">
                                Kuranda Beds can be donated at discounted pricing and ship directly to the Shelter of your choice.
                            </span>
                    </p>
                </div>
            </div>
            <div class="relative overflow-hidden pt-16">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <picture>
                        <img
                            class="rounded-xl shadow-2xl ring-1 ring-gray-900/10 aspect-[1242/701]"
                            sizes="(max-width: 1242px) 100vw, 1242px"
                            srcset="
../../img/shelterbeds-home-page-photo-200.jpg 200w,
../../img/shelterbeds-home-page-photo-663.jpg 663w,
../../img/shelterbeds-home-page-photo-908.jpg 908w,
../../img/shelterbeds-home-page-photo-1112.jpg 1112w,
../../img/shelterbeds-home-page-photo.jpg 1242w"
                            src="../../img/shelterbeds-home-page-photo.jpg"
                            alt="A Dog in an animal shelter laying on a Kuranda Dog Bed">
                    </picture>
<!--                    <img src="../../img/shelterbeds-home-page-photo.jpg" alt="A Dog in an animal shelter laying on a Kuranda Dog Bed" />-->
                    <div class="relative" aria-hidden="true">
                        <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
                    </div>
                </div>
            </div>
            <div class="mx-auto mt-12 px-6 max-w-2xl sm:text-center">
                <h2 class="mt-2 text-2xl tracking-tight text-gray-900 sm:text-1xl font-alternate">
                    Shelter staff love Kuranda Beds because they save time and money by being durable and easy to clean, while also providing comfort to the animals in their care but the best thing about Kuranda Beds is that <strong>animals love them</strong>.
                </h2>
            </div>
            <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-12 lg:px-8">
                <dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                    <div v-for="feature in features" :key="feature.name" class="relative pl-9">
                        <dt class="inline font-semibold text-gray-900">
                            <FontAwesomeIcon :icon="feature.icon" class="absolute left-0 top-1 h-6 w-6 text-indigo-600" aria-hidden="true" />
                            {{ feature.name }}
                        </dt>
                        <br>
                        <dd class="inline">{{ feature.description }}</dd>
                    </div>
                </dl>
            </div>
            <div class="overflow-hidden bg-indigo-500 py-10 my-10 mx-auto sm:text-center" id="search">
                <h2 class="mt-2 mb-6 text-3xl tracking-tight text-white sm:text-1xl font-alternate px-8">Search for the Shelter and the Animals that need your help:</h2>
                <div class="overflow-hidden max-w-5xl sm:bg-white md:shadow-2xl md:rounded-lg mx-auto">
                    <div class="px-4 py-5 sm:p-6">
                        <div class="mx-auto max-w-7xl px-6 lg:px-8">
                            <home-page-search
                                :original-search-query="props.searchQuery"
                                :original-shelter-id="props.shelterId"
                                :original-shelter-name="props.shelterName"
                                :original-search-on-load="props.searchOnLoad"
                            ></home-page-search>
                        </div>
                    </div>
                </div>
            </div>


            <frequently-asked-questions></frequently-asked-questions>

            <div class="overflow-hidden bg-gray-100 py-10 my-10 mx-auto">
                <div class="mx-auto mt-6 sm:mt-8 lg:mt-10 lg:max-w-none">
                    <dl class="grid max-w-none grid-cols-1 gap-x-8 gap-y-16 md:grid-cols-2 xl:grid-cols-4 px-6 xl:px-14">
                        <div v-for="feature in kurandaFacts" :key="feature.name" class="flex flex-col px-4 py-5 sm:p-6 bg-white shadow sm:rounded-lg">
                            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                <component :is="feature.icon" class="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <img v-if="feature.customIcon" src="../../img/maryland-flag.svg" class="max-w-10" alt="Maryland Flag">
                                {{ feature.name }}
                            </dt>
                            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                <p class="flex-auto" v-html="feature.description" />
                                <p class="mt-6">
                                    <a :href="feature.href" class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500" v-html="feature.buttonText" />
                                </p>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

        </div>
</template>

<script setup>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faDumbbell } from '@fortawesome/sharp-solid-svg-icons'
    import {
        faHeartPulse,
        faTemperatureHalf,
        faBed,
        faFaceRelieved,
        faFlagUsa,
    } from '@fortawesome/pro-solid-svg-icons'
    import {
        ArrowPathIcon,
        CloudArrowUpIcon,
        Cog6ToothIcon,
        FingerPrintIcon,
        LockClosedIcon,
        ServerIcon,
        HeartIcon,
        SunIcon,
    } from '@heroicons/vue/20/solid'
    import {
        PhoneIcon,
        EnvelopeIcon,
        UserPlusIcon,
    } from '@heroicons/vue/24/outline'
    import HomePageSearch from "@/components/HomePageSearch.vue";
    import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions.vue";
    import {onMounted, ref} from "vue";
    import {useRoute} from "../composables/useRoute.js";
    const route = useRoute();

    const features = [
        {
            name: 'Durable',
            description: 'Kuranda Beds hold up for years in a shelter and are covered by a 1 Year Chew Proof Warranty.',
            icon: faDumbbell,
        },
        {
            name: 'Healthier',
            description: 'Made from non-absorbent materials, Kuranda Beds are easy to clean and sanitize which reduces the spread of infectious diseases.',
            icon: faHeartPulse,
        },
        {
            name: 'Regulates Body Temperature',
            description: 'Kuranda Beds lift the dog up off the cold floor keeping them warm in the winter and cool in the summer with improved airflow under the bed.',
            icon: faTemperatureHalf,
        },
        {
            name: 'Comfortable',
            description: 'Kuranda\'s elevated design evenly distributes the animal\'s body weight which eliminates pressure points and reduces stress on joints.',
            icon: faBed,
        },
        {
            name: 'Reduces Stress',
            description: 'When animals sleep better, they feel healthier and are less stressed. When they are less stressed, their true personalities can really shine through which increases adoption rates.',
            icon: faFaceRelieved,
        },
        {
            name: 'Kuranda Beds are Made in the USA',
            description: 'Kuranda Beds have been manufactured and shipped from Glen Burnie, Maryland since 1987.',
            icon: faFlagUsa,
        },
    ]
    const kurandaFacts = [
        {
            name: 'Made in Maryland',
            description: 'Kuranda ShelterBeds are made in Maryland, just outside of Baltimore. Customer service, manufacturing and shipping are all based out of the same location. All order ship from our facility in Glen Burnie, Maryland.',
            buttonText: 'Learn More About Kuranda',
            href: route('about'),
            customIcon: '../../img/maryland-flag.svg',
        },
        {
            name: 'Give us a Call',
            description: 'Have a question? Don’t hesitate to give us a call at <a class="underline" href="tel:8007525308">800-752-5308</a>. Our customer advisors can answer any questions you may have about our products or the ShelterBeds program during our business hours of 9AM - 5PM ET, Monday - Friday.',
            icon: PhoneIcon,
            href: 'tel:8007525308',
            buttonText: 'Call us',
        },
        {
            name: 'Send us an Email',
            description: 'Prefer to send an email? No problem! You can send one to <a class="underline" href="mailto:contact@kuranda.com">contact@kuranda.com</a> and we will be back in touch within 24 business hours. ',
            icon: EnvelopeIcon,
            href: route('contact.create'),
            buttonText: 'Send us an Email',
        },
        {
            name: 'Register your Shelter',
            description: 'If you are in a leadership role of a Humane Organization and would like to enroll in our ShelterBeds program, please fill out the application forms below. Once your application is submitted, one of our account advisors will be in touch to finalize your account.',
            icon: UserPlusIcon,
            href: route('registration.create'),
            buttonText: 'Register',
        },
    ]
    const props = defineProps({
        searchQuery: {
            type: String,
            default: '',
        },
        shelterId: {
            type: String,
            default: '0',
        },
        shelterName: {
            type: String,
            default: '0',
        },
        showResumeBar: {
            type: String,
            default: '0',
        },
        searchOnLoad: {
            type: String,
            default: '0',
        },
    });
</script>

<style scoped>

</style>
