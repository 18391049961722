<template>
    <header class="relative isolate z-10 bg-slate-100">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:hidden mr-4">
                <button type="button" class="-m-2.5 inline-flex rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-8 w-8" aria-hidden="true" />
                </button>
            </div>
            <div class="flex flex-1">
                <a href="/" class="-m-1.5 p-1.5">
                    <span class="sr-only">Kuranda ShelterBeds</span>
                    <img class="h-12 w-auto" src="../../img/logo.svg" alt="" />
                </a>
            </div>
            <PopoverGroup class="hidden lg:flex lg:gap-x-12">
                <Popover class="relative">
                    <PopoverButton class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                        About
                        <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                        <PopoverPanel class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                            <div class="p-4">
                                <div v-for="item in aboutPrimaryLinks" :key="item.name" class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
<!--                                        <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">-->
<!--                                            <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />-->
<!--                                        </div>-->
                                    <div class="flex-auto">
                                        <a :href="item.href" class="block font-semibold text-gray-900">
                                            {{ item.name }}
                                            <span class="absolute inset-0" />
                                        </a>
<!--                                            <p class="mt-1 text-gray-600">{{ item.description }}</p>-->
                                    </div>
                                </div>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>
                <a :href="route('faq.index')" class="text-sm font-semibold leading-6 text-gray-900">FAQs</a>
                <a :href="route('blog.index')" class="text-sm font-semibold leading-6 text-gray-900">Success Stories</a>
                <Popover>
                    <PopoverButton class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                        Shelter Resources
                        <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </PopoverButton>

                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
                        <PopoverPanel class="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">
                            <div class="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                                <div v-for="item in shelterResourcesPrimaryLinks" :key="item.name" class="group relative rounded-lg p-6 text-sm leading-6 hover:bg-gray-50">
                                    <div class="flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                        <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                    </div>
                                    <a :href="item.href" class="mt-6 block font-semibold text-gray-900"
                                       :target="item.newTab && item.newTab === true ? '_blank' : '_self'"
                                       :aria-label="item.ariaLabel ? item.ariaLabel : null"
                                    >
                                        {{ item.name }}
                                        <span class="absolute inset-0" />
                                    </a>
                                    <p class="mt-1 text-gray-600">{{ item.description }}</p>
                                </div>
                            </div>
<!--                            <div class="bg-gray-50">-->
<!--                                <div class="mx-auto max-w-7xl px-6 lg:px-8">-->
<!--                                    <div class="grid grid-cols-3 divide-x divide-gray-900/5 border-x border-gray-900/5">-->
<!--                                        <a v-for="item in shelterResourcesSecondaryLinks" :key="item.name" :href="item.href" class="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">-->
<!--                                            <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />-->
<!--                                            {{ item.name }}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                        </PopoverPanel>
                    </transition>
                </Popover>
                <a :href="route('registration.create')" class="text-sm font-semibold leading-6 text-gray-900">Register for a Wish List</a>
            </PopoverGroup>

            <template v-if="isCartPage !== '1'">
                <template v-if="localIsShopper === true && localShelterId > 0">
                    <div class="flex flex-1 items-center justify-end gap-x-6">
                        <!--                    <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>-->
                        <button type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  @click="$emit('togglePreviewCart')">
                            Shopping Cart
                            <ShoppingCartIcon class="-mr-0.5 h-5 w-5 hidden sm:block" aria-hidden="true" />
                        </button>
                    </div>
                </template>
<!--                    <template v-else-if="localShelterId > 0">-->
<!--                        <a :href="route('wishList.show', localShelterId)" type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">-->
<!--                            Donate Now-->
<!--                        </a>-->
<!--                    </template>-->
                <template v-else-if="isWishListPage !== '1'">
                    <div class="hidden sm:flex flex-1 items-center justify-end gap-x-6">
                        <a href="/#search" type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            Donate Now
                        </a>
                    </div>
                </template>
            </template>
            <a :href="route('donor.index')" class="hidden lg:block text-sm font-semibold leading-6 text-gray-900" v-if="localIsLoggedIn">My Account</a>
        </nav>
        <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10" />
            <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                    <a href="/" class="-m-1.5 p-1.5">
                        <span class="sr-only">Kuranda ShelterBeds</span>
                        <img class="h-12 w-auto" src="../../img/logo.svg" alt="" />
                    </a>
                    <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="space-y-2 py-6">
                            <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                                <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    About
                                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']" aria-hidden="true" />
                                </DisclosureButton>
                                <DisclosurePanel class="mt-2 space-y-2">
                                    <DisclosureButton v-for="item in [...aboutPrimaryLinks]" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>
                                </DisclosurePanel>
                            </Disclosure>
                            <a :href="route('faq.index')" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">FAQs</a>
                            <a :href="route('blog.index')" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Success Stories</a>
                            <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                                <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    Shelter Resources
                                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']" aria-hidden="true" />
                                </DisclosureButton>
                                <DisclosurePanel class="mt-2 space-y-2">
                                    <DisclosureButton v-for="item in [...shelterResourcesPrimaryLinks]" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>
                                </DisclosurePanel>
                            </Disclosure>
                            <a :href="route('registration.create')" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Register for a Wish List</a>
                            <a :href="route('donor.index')" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" v-if="localIsLoggedIn">My Account</a>
                        </div>
                        <div class="py-6" v-if="isCartPage !== '1'">
                            <template v-if="localIsShopper === true && localShelterId > 0">
<!--                                <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>-->
                                <button type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  @click="$emit('togglePreviewCart')">
                                    Shopping Cart
                                    <ShoppingCartIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                </button>
                            </template>
<!--                            <template v-else-if="localShelterId > 0">-->
<!--                                <a :href="route('wishList.show', localShelterId)" type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">-->
<!--                                    Donate Now-->
<!--                                </a>-->
<!--                            </template>-->
                            <template v-else-if="isWishListPage !== '1'">
                                <a href="/#search" type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                    Donate Now
                                </a>
                            </template>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
    <div class="flex items-center gap-x-6 bg-blue-100 px-6 py-2.5 sm:px-3.5 sm:before:flex-1" v-if="localShelterId > 0 && localShowResumeBar">
        <p class="text-sm leading-6 text-white">
            <a :href="route('wishList.show', localShelterId)" class="font-semibold">
                Resume donation
                <template v-if="localShelterName && localShelterName !== ''">
                    to {{ localShelterName }}
                </template>
                &nbsp;<span aria-hidden="true">&rarr;</span>
            </a>
        </p>
        <div class="flex flex-1 justify-end">
            <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]" @click.prevent="dismissResumeBar">
                <span class="sr-only">Dismiss resume helper link</span>
                <svg class="h-5 w-5 text-black" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
    import {
        Dialog,
        DialogPanel,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverPanel,
    } from '@headlessui/vue'
    import {
        Bars3Icon,
        ChartPieIcon,
        CursorArrowRaysIcon,
        FingerPrintIcon,
        SquaresPlusIcon,
        XMarkIcon,
        ShoppingCartIcon,
    } from '@heroicons/vue/24/outline'
    import {
        BanknotesIcon, BuildingStorefrontIcon,
        ChevronDownIcon,
        PhoneIcon,
        PlayCircleIcon,
        RectangleGroupIcon,
        WrenchScrewdriverIcon
    } from '@heroicons/vue/20/solid'
import {useRoute} from "../composables/useRoute.js";
    const route = useRoute();

    const shelterResourcesPrimaryLinks = [
        {
            name: 'Promotional Material',
            description: 'Find everything you need to get the beds your animals need.',
            href: route('dashboard.search'),
            icon: WrenchScrewdriverIcon,
        },
        {
            name: 'Fundraise with Kuranda',
            description: 'Earn money by recommending Kuranda Beds',
            href: route('partner-program'),
            icon: BanknotesIcon,
        },
        {
            name: 'Purchasing by Shelters',
            description: 'Discounted prices for Shelters and Rescues',
            href: 'https://www.kurandapro.com?utm_source=shelterbeds.org&utm_medium=link&utm_campaign=Shelter+Beds+Navigation+Link',
            icon: BuildingStorefrontIcon,
            newTab: true,
            ariaLabel: 'Link to kurandapro.com opens in a new tab',
        },
    ]
    const shelterResourcesSecondaryLinks     = [
        { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
        { name: 'Contact Us', href: route('contact.create'), icon: PhoneIcon },
        { name: 'View all products', href: '#', icon: RectangleGroupIcon },
    ]
    const aboutPrimaryLinks = [
        {
            name: 'How the ShelterBeds Program Works',
            //description: 'Get a better understanding where your traffic is coming from',
            href: route('how-it-works'),
            //icon: WrenchScrewdriverIcon,
        },
        {
            name: 'About Kuranda USA',
            //description: 'Speak directly to your customers with our engagement tool',
            href: route('about'),
            //icon: BanknotesIcon,
        },
        {
            name: 'Call us 800-752-5308',
            //description: 'Your customers’ data will be safe and secure',
            href: 'tel:8007525308',
            //icon: BuildingStorefrontIcon,
        },
    ]

    const mobileMenuOpen = ref(false)
    const emit = defineEmits(['togglePreviewCart']);
    function dismissResumeBar() {
        axios.post(route('resume.dismiss'))
            .then(function (response) {
                localShowResumeBar.value = false;
            })
            .catch(function (error) {
                //
            })
            .finally(function () {
                //
            });
    }
    const props = defineProps({
        isShopper: {
            type: String,
            default: '0',
        },
        shelterId: {
            type: String,
            default: '0',
        },
        shelterName: {
            type: String,
            default: '0',
        },
        showResumeBar: {
            type: String,
            default: '0',
        },
        isCartPage: {
            type: String,
            default: '0',
        },
        isWishListPage: {
            type: String,
            default: '0',
        },
        isLoggedIn: {
            type: String,
            default: '0',
        },
    });
    const localIsShopper = ref(false);
    const localShelterId = ref(null);
    const localShelterName = ref(null);
    const localIsLoggedIn = ref(false);
    const localShowResumeBar = ref(false);
    onMounted(() => {
        localIsShopper.value = props.isShopper === '1';
        localShelterId.value = props.shelterId === '0' ? null : parseInt(props.shelterId);
        localShelterName.value = props.shelterName === '0' ? null : props.shelterName;
        localShowResumeBar.value = props.showResumeBar !== '0';
        localIsLoggedIn.value = props.isLoggedIn !== '0';
    })
</script>
