<template>
    <div>
        <label for="shelter-search-query" class="block font-medium leading-6 text-white md:text-gray-900">Search for a Shelter</label>
        <div class="relative mt-2">
            <form @submit.prevent="search()" class="max-w-xl flex">
                <input type="text" name="shelter-search-query" id="shelter-search-query" class="block w-full rounded-md rounded-r-none border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="query" placeholder="Enter a Zip/Postal Code" />
                <button type="submit" class="rounded-tr-md rounded-br-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Search</button>
            </form>
        </div>
        <template v-if="loading === true">
            <div class="d-flex justify-content-center">
                <div role="status" class="m-8">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading search results...</span>
                </div>
            </div>
        </template>
        <template v-if="noneFound === true && !loading">
            <div class="card mb-2 px-2">
                <div class="card-body text-center" role="alert">
                    <h2 class="mt-4 text-2xl font-bold tracking-tight text-gray-900">
                        No results found.
                    </h2>
                </div>
            </div>
        </template>
        <template v-if="errorMessage !== null && !loading">
            <div class="card mb-2 px-2">
                <div class="card-body text-center" role="alert">
                    <h2 class="mt-4 text-2xl font-bold tracking-tight text-gray-900" v-html="errorMessage"/>
                </div>
            </div>
        </template>
        <template v-if="matches.length && !loading">
            <p class="my-4">We found some organizations near that address. <em>If any of them look like yours, instead of creating a new account</em> please email <a href="mailto:kurandapro@kuranda.com">kurandapro@kuranda.com</a> or <a href="tel:8007525308">call us at 800-752-5308</a> and mention the organization name and Shelter ID below and that you're working with. We'll be happy to assist you. </p>
            <p class="my-4">If you don't see a match, please proceed with the registration form.</p>
            <ul class="list-disc">
                <li class="my-2 ml-4" v-for="match in matches" :key="match.id">
                    {{ match.text }}
                </li>
            </ul>
        </template>
    </div>
</template>
<script>
import * as _ from 'lodash-es'
import {useRoute} from "../composables/useRoute.js";
export default {
    name: 'RegisterSearchFirst',
    data() {
        return {
            query: '',
            matches: [],
            loading: false,
            errorMessage: null,
            noneFound: false,
            search_type: 'location',
            route: null,
        };
    },
    mounted: function() {
        this.route = useRoute();
    },
    emits: ['searchCompleted'],
    methods: {
        search() {
            if (_.isEmpty(this.query)) {
                this.errorMessage = 'Please enter a search query.';
                return;
            }
            this.matches = [];
            this.loading = true;
            this.errorMessage = null;
            this.noneFound = false;
            const that = this;
            axios.post(this.route('getSheltersForLocation'), {
                address: this.query,
            })
                .then(function (response) {
                    if (!response.data.organizations || !response.data.organizations.length) {
                        that.noneFound = true;
                        return;
                    }
                    for (const i in response.data.organizations) {
                        that.matches.push({
                            id: response.data.organizations[i].id,
                            text: `${response.data.organizations[i].name} - Shelter ID ${response.data.organizations[i].id}`,
                        });
                    }
                })
                .catch(function (error) {
                    if (error.response && error.response.status === 429) {
                        that.errorMessage = 'Too many searches, please try again in another minute.';
                        return;
                    }
                    that.errorMessage = 'There was an error. Unable to search at this time.';
                })
                .finally(function () {
                    that.loading = false;
                    that.$emit('searchCompleted');
                });
        },
    }
}
</script>
<style scoped>

</style>
