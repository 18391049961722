<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
    <TransitionRoot as="template" :show="props.cartMenuOpen === true" @close="$emit('togglePreviewCart')">
        <Dialog as="div" class="relative z-10">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-stretch justify-center text-center sm:items-center sm:px-6 lg:px-8">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-105" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-105">
                        <DialogPanel class="flex w-full max-w-3xl transform text-left text-base transition sm:my-8">
                            <div class="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-8">
                                <div class="flex items-center justify-between px-4 sm:px-6 lg:px-8">
                                    <div class="text-lg font-medium text-gray-900">Shopping Cart <span v-if="organization"> - {{ organization }}</span></div>
                                    <button type="button" class="text-gray-700 hover:text-gray-500" @click="$emit('togglePreviewCart')">
                                        <span class="sr-only">Close</span>
                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <template v-if="loadingError === true">
                                    <p class="m-8 text-lg leading-8 text-gray-600">
                                        There was an error loading your cart details. Please <a href="/cart" class="underline">visit the cart page to see complete info</a>.
                                    </p>
                                </template>
                                <template v-else-if="isLoading === true">
                                    <div role="status" class="m-8">
                                        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                        <span class="sr-only">Loading cart details...</span>
                                    </div>
                                </template>
                                <template v-else-if="cartProducts.length === 0">
                                    <p class="m-8 text-lg leading-8 text-gray-600">
                                        Your shopping cart is currently empty. <a :href="continueShoppingLink" v-if="isWishListPage === '0'" class="underline">Continue Shopping.</a>
                                    </p>

                                </template>
                                <template v-else>
                                    <section aria-labelledby="cart-heading">
                                        <div id="cart-heading" class="sr-only">Items in your shopping cart</div>

                                        <ul role="list" class="divide-y divide-gray-200 px-4 sm:px-6 lg:px-8">
                                            <li v-for="product in cartProducts" :key="product.id" class="flex py-8 pr-0 text-sm sm:items-center sm:pr-8">
                                                <div class="aspect-[1/1] w-24 sm:w-32">
                                                    <img :src="product.image" :alt="product.image_alt_text" class="p-2 h-full w-full object-contain object-center lg:h-full lg:w-full rounded-lg border border-gray-200" />
                                                </div>
                                                <div class="ml-4 grid flex-auto grid-cols-1 grid-rows-1 items-start gap-x-5 gap-y-3 sm:ml-6 sm:flex sm:items-center sm:gap-0">
                                                    <div class="row-end-1 flex-auto sm:pr-6">
                                                        <div class="font-medium text-gray-900">
                                                            {{ product.name }}
                                                        </div>
                                                        <p class="mt-1 text-gray-500" v-if="product.description">{{ product.description }}</p>
                                                        <p class="mt-1 text-gray-500">Quantity: {{ product.quantity }}</p>
                                                    </div>
                                                    <p class="row-span-2 row-end-2 flex-1 font-medium text-gray-900 sm:order-1 sm:ml-6 sm:text-right">
                                                        <span v-if="product.original_price" class="block text-sm font-medium text-gray-700 line-through">
                                                            ${{ product.original_price.toFixed(2) }}
                                                        </span>
                                                        ${{ product.unit_price.toFixed(2) }}
                                                    </p>

                                                </div>
                                            </li>
                                        </ul>
                                    </section>

                                    <section aria-labelledby="summary-heading" class="mt-auto sm:px-6 lg:px-8">
                                        <div class="bg-gray-50 p-6 sm:rounded-lg sm:p-8">
                                            <div id="summary-heading" class="sr-only">Order summary</div>

                                            <div class="flow-root">
                                                <dl class="-my-4 divide-y divide-gray-200 text-sm">
                                                    <div class="flex items-center justify-between py-4">
                                                        <dt class="text-gray-600">Subtotal</dt>
                                                        <dd class="font-medium text-gray-900">${{ cartProductTotalPrice.toFixed(2)}} </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>
                                    </section>

                                    <div class="mt-8 flex justify-end px-4 sm:px-6 lg:px-8">
                                        <a :href="continueShoppingLink" v-if="isWishListPage === '0'" class="mr-4 rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Continue Shopping</a>
                                        <a :href="route('cart.index')" class="mr-4 rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Edit Cart</a>
                                        <a :href="route('checkout.create')" class="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Continue to Checkout</a>
                                    </div>
                                </template>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {ref, watch} from 'vue'
import * as _ from "lodash-es";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import {useRoute} from "../composables/useRoute.js";
const route = useRoute();

const props = defineProps({
    cartMenuOpen: {
        type: Boolean,
        required: true,
    },
    isWishListPage: {
        type: String,
        default: '0',
    },
    sendGoogleEvents: {
        type: Boolean,
        default: false,
    }
});
const emit = defineEmits(['togglePreviewCart']);
const isLoading = ref(true);
const loadingError = ref(false);
const cartProducts = ref([]);
const cartProductsJsForGoogle = ref([]);
const cartProductTotalPrice = ref(0);
const continueShoppingLink = ref(null);
const organization = ref(null);
const cartLoadedOnce = ref(false);
watch(() => props.cartMenuOpen, async (newState, oldState) => {
    if (!newState || cartLoadedOnce === true) {
       return;
   }
   loadCart();
});
function loadCart()
{
    cartLoadedOnce.value = true;
    axios.get('/cart/json')
        .then(function (response) {
            cartProducts.value = _.get(response.data, 'cart_contents.products', []);
            cartProductTotalPrice.value = _.get(response.data, 'cart_contents.product_total_price');
            continueShoppingLink.value = response.data.continue_shopping_link;
            organization.value = response.data.shelter_name;
            cartProductsJsForGoogle.value = _.get(response.data, 'cart_products_js_for_google', []);
            if (props.sendGoogleEvents) {
                sendGaEvent();
            }
        })
        .catch(function (error) {
            loadingError.value = true;
        })
        .finally(function () {
            isLoading.value = false;
        });
}
function sendGaEvent()
{
    window.gtag('event', 'view_cart', {
        value: _.get(cartProductsJsForGoogle.value, 'total', 0),
        currency: _.get(cartProductsJsForGoogle.value, 'currency', ''),
        items: _.get(cartProductsJsForGoogle.value, 'products', []),
    })
}
</script>
