<script setup>

import FormField from "./FormField.vue";
import * as _ from "lodash-es";
import {onMounted, ref} from "vue";
import {useRoute} from "../composables/useRoute.js";
const route = useRoute();
function submitForm() {
    this.submitButtonIsDisabled = true;
}
function getSessionErrorsForField(fieldName)
{
    return _.get(props.formValidationErrors, fieldName, []);
}
function hasSessionErrorsForField(fieldName)
{
    return getSessionErrorsForField(fieldName).length;
}
const props = defineProps({
    inputName: {
        type: String,
        default: null,
    },
    inputEmail: {
        type: String,
        default: null,
    },
    inputCompany: {
        type: String,
        default: null,
    },
    inputOrderNumber: {
        type: String,
        default: null,
    },
    inputPhone: {
        type: String,
        default: null,
    },
    inputComments: {
        type: String,
        default: null,
    },
    csrfToken: {
        type: String,
    },
    cloudflareCaptchaSiteKey: {
        type: String,
    },
    formValidationErrors: {
        type: Object,
        default: {},
    },
})
const name = ref('');
const email = ref('');
const company = ref('');
const phone = ref('');
const orderNumber = ref('');
const comments = ref('');
const submitButtonIsDisabled = ref(false);
onMounted(() => {
    name.value = props.inputName;
    email.value = props.inputEmail;
    phone.value = props.inputPhone;
    company.value = props.inputCompany;
    orderNumber.value = props.inputOrderNumber;
    comments.value = props.inputComments;
})
</script>

<template>
    <div class="pb-24 pt-12 sm:pb-32 sm:pt-18 lg:mx-auto lg:grid lg:max-w-7xl lg:pt-24">
        <div class="px-6 lg:px-8">
            <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-xl">
                <h1 class="text-3xl font-bold tracking-tight text-gray-900">Contact Us</h1>
<!--                <p class="mt-2 text-lg leading-8 text-gray-600">Proin volutpat consequat porttitor cras nullam gravida at orci molestie a eu arcu sed ut tincidunt magna.</p>-->
                <form :action="route('contact.store')" method="POST" class="mt-16">
                    <input type="hidden" :value="props.csrfToken" name="_token" autocomplete="off">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <form-field
                                field-name="name"
                                label-text="First and Last Name"
                                v-model="name"
                                :error-messages="getSessionErrorsForField('name')"
                            ></form-field>
                        </div>
                        <div>
                            <form-field
                                field-name="email"
                                field-type="email"
                                label-text="Email"
                                v-model="email"
                                :error-messages="getSessionErrorsForField('email')"
                            ></form-field>
                        </div>
                        <div>
                            <form-field
                                field-name="phone"
                                label-text="Phone"
                                field-type="tel"
                                auto-complete-type="tel"
                                v-model="phone"
                                :error-messages="getSessionErrorsForField('phone')"
                                :optional="true"
                            ></form-field>
                        </div>
                        <div>
                            <form-field
                                field-name="order_number"
                                label-text="Order Number"
                                v-model="orderNumber"
                                :optional="true"
                                :error-messages="getSessionErrorsForField('order_number')"
                            ></form-field>
                        </div>
                        <div>
                            <form-field
                                field-name="company"
                                label-text="Company"
                                v-model="company"
                                :optional="true"
                                :error-messages="getSessionErrorsForField('company')"
                            ></form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <label for="comments" class="block text-sm font-medium leading-6 text-gray-900">
                                Comments or Questions
                            </label>
                            <div class="mt-2">
                                <textarea
                                    required
                                    rows="4"
                                    name="comments"
                                    id="comments"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    v-model="comments"
                                    :aria-invalid="hasSessionErrorsForField('comments') ? true : null "
                                    :aria-describedby="hasSessionErrorsForField('comments') ? 'comments_error' : null"
                                />
                            </div>
                            <p class="mt-2 text-sm text-red-600" v-if="hasSessionErrorsForField('comments')" id="comments_error" v-html="getSessionErrorsForField('comments').join('<br>')" />
                        </div>
                        <div class="sm:col-span-2">
                            <span class="block text-sm font-medium leading-6 text-gray-900">
                                Complete the "Verify you are human test"
                            </span>
                            <div class="mt-2">
                                <div class="cf-turnstile" :data-sitekey="cloudflareCaptchaSiteKey"></div>
                            </div>
                            <p class="mt-2 text-sm text-red-600" v-if="hasSessionErrorsForField('cf-turnstile-response')" id="captcha_error" v-html="getSessionErrorsForField('cf-turnstile-response').join('<br>')" />
                        </div>
                        <div class="sm:col-span-2">
                            <button type="submit" :disabled="submitButtonIsDisabled" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
