import './bootstrap';
import { createApp } from 'vue';
import HeaderNav from "@/components/HeaderNav.vue";
import CartPreviewModal from "@/components/CartPreviewModal.vue";
import HomePage from "@/components/HomePage.vue";
import PageFooter from "@/components/PageFooter.vue";
import FrequentlyAskedQuestions from "@/components/FrequentlyAskedQuestions.vue";
import CartForm from "@/components/CartForm.vue";
import Checkout from "@/components/Checkout.vue";
import ContactForm from "@/components/ContactForm.vue";
import CheckoutTributeCardForm from "@/components/CheckoutTributeCardForm.vue";
import RegisterSearchFirst from "@/components/RegisterSearchFirst.vue";
import DashboardSearchForMyShelter from "@/components/DashboardSearchForMyShelter.vue";
import GenericHelpShelterSearch from "@/components/GenericHelpShelterSearch.vue";
import CognitoFormEmbed from "@/components/CognitoFormEmbed.vue";
import SubscribeForm from "@/components/SubscribeForm.vue";
import {
    ExclamationTriangleIcon,
    CheckCircleIcon,
    // names that start with x- make blade look for a blade component
    XCircleIcon as ExCircleIcon,
    InformationCircleIcon,
    ExclamationCircleIcon,
} from '@heroicons/vue/20/solid';
import * as _ from "lodash-es";
import.meta.glob([
    '../img/**',
]);
import { Ziggy } from './ziggy-public.js';
import { ZiggyVue } from 'ziggy-js';
import 'lazysizes';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import emojiRegex from "emoji-regex";

window.emojiRegex = emojiRegex;

lazySizes.cfg.lazyClass = 'shelterBedsLazyload';

createApp({
    data: function () {
        return {
            previewCartVisible: false,
        }
    },
    components: {
        HeaderNav,
        CartPreviewModal,
        HomePage,
        PageFooter,
        FrequentlyAskedQuestions,
        CartForm,
        Checkout,
        CheckoutTributeCardForm,
        ContactForm,
        SubscribeForm,
        RegisterSearchFirst,
        DashboardSearchForMyShelter,
        GenericHelpShelterSearch,
        CognitoFormEmbed,
        ExclamationCircleIcon,
        ExclamationTriangleIcon,
        CheckCircleIcon,
        ExCircleIcon,
        InformationCircleIcon,
        FontAwesomeIcon,
    },
    mounted() {
        this.accessibilityLinksHelper();
    },
    methods: {
        togglePreviewCart() {
            this.previewCartVisible = !this.previewCartVisible;
        },
        getGlobal(variable) {
            return _.get(window.shelterBeds, variable);
        },
        /** this is 'show' for tailwind assuming it has hidden class **/
        showElementId(elementId) {
            if (document.getElementById(elementId).classList.contains('hidden')) {
                document.getElementById(elementId).classList.remove('hidden');
            }
        },
        accessibilityLinksHelper() {
            // mark all BR tags aria hidden
            const brElements = document.querySelectorAll('br');
            brElements.forEach((brElement) => {
                brElement.setAttribute('aria-hidden', 'true');
            });
            //
            const aElements = document.querySelectorAll('a');
            aElements.forEach((aElement) => {
                // has no href attribute, skip it.
                if (!aElement.getAttribute('href') || aElement.getAttribute('href') === '') {
                    return;
                }
                const linkHref = aElement.getAttribute('href').toLowerCase();
                // dont change phone links
                if (linkHref.startsWith('tel:')) {
                    return;
                }
                // only check full urls, not relative paths.
                if (linkHref.startsWith('http')) {
                    try {
                        const parsedUrl = new URL(linkHref);
                        // if is a link to url other than kuranda.com and missing target attr, add it  to open in new tab.
                        if (parsedUrl.hostname && !parsedUrl.hostname.endsWith(window.location.hostname) && !aElement.getAttribute('target')) {
                            aElement.setAttribute('target', '_blank');
                        }
                    } catch (e) {
                        // skip it, unknow format.
                    }
                }
                // if already has aria label, don't change. skip it.
                if (aElement.getAttribute('arial-label') && aElement.getAttribute('arial-label') !== '') {
                    return;
                }
                let arialLabelText = '';
                // check email links first since those don't have a target attribute to look for, and we want to annotate all
                // of these.
                const anchorText = aElement.innerHTML.replace('"', '').replace(/(<([^>]+)>)/gi, '').replace('&nbsp;', '');
                if (linkHref.substring(0, 7).toLowerCase() === 'mailto:') {
                    arialLabelText = `Email Address ${anchorText.replace('mailto:', '')}, this Link will Open in an Email window.`;
                    aElement.setAttribute('aria-label', arialLabelText);
                    return;
                }
                // not a link that opens in new window, skip it. assume any target that's not blank
                // is a new window since we have some non-standard cases like 'blank' and 'new' which
                // still trigger a new tab/window.
                if (!aElement.getAttribute('target') || aElement.getAttribute('target') === '') {
                    return;
                }
                // remove quotation marks that will break html and attempt to strip html tags.
                if (linkHref.substring(linkHref.length - 4) === '.pdf') {
                    arialLabelText = `${anchorText.replace('"', '')}, this link will download the PDF to your computer or open the PDF in a new tab.`;
                } else {
                    arialLabelText = `${anchorText.replace('"', '')}, this link will open in a new tab.`;
                }
                aElement.setAttribute('aria-label', arialLabelText);
            });
        }
    },
}).use(ZiggyVue, Ziggy).mount('#app');
shelterBeds.googleAnalyticsSendCustomEvent = function(eventName, eventArgs) {
    window.gtag('event', eventName, eventArgs);
};
shelterBeds.googleAnalyticsSendPurchase = function () {
    window.gtag('event', 'purchase', {
        transaction_id: _.get(shelterBeds, 'orderDetailsJsForGoogle.id', ''),
        affiliation: 'ShelterBeds',
        value: _.get(shelterBeds, 'orderDetailsJsForGoogle.grand_total', 0),
        tax: _.get(shelterBeds, 'orderDetailsJsForGoogle.tax_total', ''),
        shipping: _.get(shelterBeds, 'orderDetailsJsForGoogle.shippingTotal', ''),
        currency: _.get(shelterBeds, 'orderDetailsJsForGoogle.currency', ''),
        items: _.get(shelterBeds, 'orderDetailsJsForGoogle.products', []),
    });
};
shelterBeds.copyToClipBoard = (content, success, failure) => {
    navigator.clipboard.writeText(content).then(
        () => {
            success()
        },
        () => {
            failure()
        });
}
shelterBeds.initializeBackToTop = () => {
    document.getElementById('back-to-top').addEventListener('click', (e) => {
        const scrollStep = -window.scrollY / (500 / 15);
        const scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }, false);
    let scrollToTopIsVisible = false;
    window.setInterval(() => {
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
            // scroll to top link should be visible
            if (scrollToTopIsVisible === true) {
                // already toggled on, no changed needed.
                return;
            }
            document.getElementById('back-to-top').style.display = 'block';
            scrollToTopIsVisible = true;
            return;
        }
        // in top 1000px of page
        if (scrollToTopIsVisible === false) {
            // already toggled off, no changed needed.
            return;
        }
        document.getElementById('back-to-top').style.display = 'none';
        scrollToTopIsVisible = false;
    }, 250);
}
document.addEventListener('DOMContentLoaded', () => {
    shelterBeds.initializeBackToTop();
});
